.calendar {
  &__timeline-content {
    width: calc(100% - 63px);
  }

  &__timeline-sidebar {
    padding-top: 39px;
  }

  &__house-type-button {
    margin-right: 5px;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__background {
    position: absolute;
    background: url(../images/timeline-background.png) left top repeat;
    background-size: 31px 25px;

    &.is-compact {
      background-size: 22px 20px;
    }
  }

  &__scroller {
    flex: 1 1 auto;
    -webkit-overflow-scrolling: inherit;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
  }

  &__wrapper {
    padding-bottom: 30px;
  }

  &__days-wrapper {
    z-index: 70;
    position: relative;

    &.is-sticky {
      overflow: hidden;
      position: fixed;
      top: 70px;
    }
  }

  &__days-wrapper-el {
    background-color: white;
  }

  &__day-wrapper {
    flex-direction: column;
    font-weight: 500;
    color: #576067;
    min-width: 31px;
    height: 44px;
    align-items: center;
    justify-content: center;
    border-right: 1px solid white;
    display: flex;
    background-color: white;

    &.is-compact {
      min-width: 22px;
    }

    &.is-weekend {
      background-color: #f0f1f1;
      border-right-color: #f0f1f1;

      &.is-today {
        background-color: $body-font-color;
        color: $white;
      }
    }

    &.is-first {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        border-left: 2px solid $body-font-color;
        height: 100%;
        left: 0;
      }
    }

    &.is-today {
      background-color: $body-font-color;
      color: $white;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $body-font-color;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &.is-selected {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 1px);
        height: 100%;
        z-index: 1;
        background: black;
        opacity: 0.2;
      }
    }
  }

  &__day-name-icon {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
  }

  &__apartments {
    min-width: 63px;
    border-right: 1px solid $platinum;
    overflow: hidden;
  }

  &__apartment-extras {
    width: 3px;
    border-radius: 1px;
    height: calc(100% - 6px);
    margin-top: 2px;
    transform: rotate(25deg);
    margin-left: 2px;

    &__description-tooltip {
      .tooltip-inner {
        max-width: 300px;
      }
    }

    &__description-row {
      height: 19px;

      &.is-special-local {
        color: #8c0772;
      }
    }

    &.has-garden {
      background-color: #2c8f41;
    }

    &.has-animals {
      background-color: $reservation-animals-color;
    }

    &.is-special-local {
      background-color: #8c0772;
    }
  }

  &__apartment-name {
    font-weight: 500;
    color: #576067;
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;

    &.is-no-animals {
      color: $reservation-animals-color;
    }

    &.is-compact {
      height: 20px;
    }

    &.is-selected {
      background-color: #00000020;
      border-right: 0;
    }
  }

  &__apartment-status-wrapper {
    padding: 10px;
  }

  &__apartment-status {
    width: 7px;
    height: 7px;
    border-radius: 50%;

    &.is-dirty {
      background-color: #dd3535;
    }

    &.is-waiting {
      background-color: #f6d73e;
    }

    &.is-to-complete {
      background-color: #f6d73e;
    }

    &.is-clean {
      background-color: #2c8f41;
    }
  }

  &__day-name,
  &__month-name {
    pointer-events: none;
    text-transform: uppercase;
    font-size: 8px;

    &.is-compact {
      font-size: 7px;
    }
  }

  &__day-date {
    pointer-events: none;
    font-size: 14px;

    &.is-compact {
      font-size: 11px;
    }
  }

  &__timeline-wrapper {
    .date-picker__wrapper {
      width: 280px;

      .rdrCalendarWrapper {
        width: 100%;
      }
    }
  }

  &__full-month-name {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    margin-left: 30px;
    margin-right: 30px;
    border: none !important;
    display: flex;
    align-items: center;
    width: initial;
    padding: 0;
  }

  &__today-button {
    color: $body-font-color;
    padding-left: 30px;
  }

  &__today-button-icon {
    position: absolute;
    font-size: 18px;
    left: 10px;
    margin: auto;
    display: flex;
    align-items: center;
  }

  &__today-full-date {
    color: #adb5bd;
  }

  &__scroll-top-btn-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  &__scroll-top-btn,
  &__scroll-bottom-btn {
    z-index: 60;
    position: fixed;
    bottom: 30px;
    width: 30px;
    height: 30px;
    background-color: #576067;

    &:hover {
      filter: brightness(1.3);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__scroll-top-btn {
    border-radius: 0 50% 50% 0;
    right: 40px;
  }

  &__scroll-bottom-btn {
    border-radius: 50% 0 0 50%;
    right: 70px;
  }

  &__month-button {
    padding: 0 0 3px;
    font-size: 10px;
    font-weight: 500;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &__button {
    color: $white;
    transition: none;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: $white;
    }

    &.is-filtered {
      opacity: 0.5;
    }
  }
}

.calendar-content {
  position: relative;
  width: 100%;

  &.is-sticky {
    margin-top: 98px;
  }

  &__mask {
    z-index: 60;
    opacity: 0.5;
    background-color: #ffffff;
    position: absolute;
  }

  &__not-rentable-mask {
    position: absolute;
    background-color: #00000020;
    width: 30px;
    border: 0;
  }

  &__col {
    position: absolute;
    border-left: 1px solid $platinum;
    border-top: 1px solid $platinum;
    z-index: 40;

    &.is-today {
      border-left: 2px dashed #afafaf;
      border-right: 2px dashed #afafaf;
    }

    &.is-first {
      border-left-width: 2px;
      border-left-color: $body-font-color;
    }
  }

  &__col,
  &__row {
    &.is-selected {
      pointer-events: none;
      position: absolute;
      background-color: #00000020;
      z-index: 61;
      width: 30px;
      border: 0;
    }
  }

  &__icon {
    margin-left: 3px;

    &:before {
      color: white !important;
    }
  }

  &__status-booking {
    width: 12px;
    height: 11px;
    background-color: #fff;
    color: #000;
    z-index: 1;
    padding: 0 3px;
    text-align: center;
    line-height: 1.19;
    margin-left: 4px;
    font-weight: bold;

    &.is-in-tooltip {
      margin: 0;
      padding: 6px 0 0;
      height: 24px;
      font-size: 13px;
    }

    &.is-after-vip {
      margin: 0 30px 0 0;
    }
  }

  &__item {
    height: 24px;
    position: absolute;
    background: #5d5d5d;
    border-color: #5d5d5d;
    display: flex;
    align-items: center;
    transform: skewX(-35deg);
    z-index: 50;
    opacity: 0.8;

    &.is-compact {
      height: 19px;
    }

    &.is-edit.item {
      cursor: all-scroll;
    }

    &.is-filtered {
      opacity: 0.5;
    }

    &.is-extended {
      z-index: 49;
      transform: skewX(0deg);
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: skewX(-35deg);
        background-color: white;
        left: -50%;
      }
    }

    &.is-dragged {
      border: 1px solid #393939;
      background-color: #676767;
    }
  }

  &__item-resizer {
    cursor: ew-resize;
    width: 20px;
    height: 100%;
    position: absolute;
    background-color: #670000;

    &.is-left {
      left: 0;
    }

    &.is-right {
      right: 0;
    }

    &.is-dragged {
      opacity: 0.8;
    }
  }

  &__item-text {
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    position: absolute;
    text-align: center;
    color: #f9f9f9;
    letter-spacing: 1px;
    font-size: 11px;
    transform: skewX(35deg);
  }
}

.timeline-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 6px;
  line-height: 18px;
  cursor: pointer;

  &__el {
    flex: 1 1 auto;
    font-size: 16px;
    border-radius: 2px;
    text-align: center;
    padding: 3px 8px;
    border: 1px solid #e6e7e8;
    opacity: 0.3;
    color: #576067;

    &.is-active {
      color: #e6e7e8;
      opacity: 1;
      border: 1px solid #576067;
      background-color: #576067;
    }
  }
}

.calendar-day-tooltip {
  &__day {
    font-size: 11px;
    background-color: #333;
    line-height: 1.6;
    letter-spacing: normal;
    color: $white;
    text-align: left;
    padding: 11px 14px;
  }

  &__header {
    font-size: 13px;
  }

  &__hr {
    height: 1px;
    width: 100%;
    background-color: $white;
    border: 0;
    margin: 11px -14px;
    padding: 0 14px;
  }

  .tooltip-inner {
    padding: 0;
    max-width: 200px;
  }

  .tooltip.show {
    width: 200px;
    opacity: 1;
  }
}

.calendar-tooltip {
  .tooltip-inner {
    padding: 0;
    max-width: 240px;
  }

  .tooltip.show {
    width: 250px;
    opacity: 1;
  }

  &__lower,
  &__upper {
    color: $white;
    text-align: left;
    padding: 11px 14px;
    font-size: 11px;
    background-color: #403f3f;
    line-height: 1.6;
    letter-spacing: normal;
  }

  &__upper {
    background-color: #333333;
  }

  &__lower {
    border-top: 1px solid #fff;
  }

  &__el {
    margin-bottom: 2px;

    &.is-placeholder {
      height: 11px;
      margin-bottom: 9px;

      // Animation
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: placeholderAnimate;
      background: #f6f7f8; // Fallback
      background: linear-gradient(to right, #eee 2%, #a7a7a7 18%, #eee 33%);
      background-size: 800px; // Animation Area

      &:nth-child(even) {
        width: 65%;
      }

      &:nth-child(odd) {
        width: 85%;
      }
    }
  }

  &__icon {
    width: 13px;
    color: $gold-light;
    font-size: 15px;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }

    &.is-darker {
      opacity: 0.3;
    }
  }

  &__status {
    min-height: 24px;
    margin: 2px -14px 7px;
    padding: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &.is-extended:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 40px;
      transform: skewX(-35deg);
      right: -10px;
    }

    &.is-vip.is-extended:after {
      right: 25px;
    }

    &.is-vip {
      .vip-mark {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        background: black;
        align-items: center;
        width: 45px;
        height: 100%;
        justify-content: center;
      }
    }
  }
}

.calendar-map {
  svg {
    height: auto;
    width: 100%;

    .cls-1,
    .cls-2 {
      display: none !important;
    }
  }
}

.calendar-warning-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10%;
  bottom: 0;
  margin: auto;
  height: 100%;

  &__wrapper {
    background: #ffbc00;
    color: #fff;
    text-align: center;
    padding: 30px 36px;
    font-size: 14.4px;
  }

  &__icon {
    margin-top: -10px;
  }

  &__text {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  &__header {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 12px;
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    background: #eef2f7;
    color: #6e757f;
    font-size: 14px;

    &:last-child {
      background: transparent;
      color: #ffffff;
      border: 1px solid #ffffff;
      margin-top: 10px;
    }
  }
}

.calendar-scrollbar {
  &__drag-area {
    &.is-dragging {
      z-index: 100;
      position: fixed;
      height: 100vh;
      top: 0;
    }
  }

  &__wrapper {
    z-index: 101;
    position: fixed;
    width: calc(100% - 400px);
    left: 365px;
    bottom: 15px;
    height: 10px;
    background-color: lightgrey;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      width: calc(100% - 20px);
      left: 10px;
    }

    &.is-mobile {
      width: calc(100% - 20px);
      left: 10px;
    }
  }

  &__inner {
    width: 100px;
    height: 10px;
    margin: auto;
    background-color: grey;
    border-radius: 10px;
    cursor: grab;
    position: absolute;
    right: 0;
  }
}

.calendar-edit-abort-icon {
  border-radius: 50%;
  background: grey;
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  z-index: 51;
}

.calendar-content__session {
  height: 24px;
  position: absolute;
  border: 2px solid #d9bfd9;
  transform: skew(-35deg);
  z-index: 50;
  pointer-events: none;

  &.is-compact {
    height: 19px;
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

;@import "sass-embedded-legacy-load-done:169";