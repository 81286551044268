.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-grab {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.text-strong {
  font-weight: 600 !important;
}

.text-semi-strong {
  font-weight: 500 !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-thin {
  font-weight: 400 !important;
}

.text-wrap {
  overflow-wrap: anywhere;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.w-max-content {
  width: max-content;
}

.text-linebreaksbr {
  white-space: pre-line;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-green {
  color: $green;
}

.text-green-dark {
  color: $green-dark !important;
}

.text-success-lighten {
  color: #a2ebb1;
}

.text-strikethrough {
  text-decoration: line-through;
}

.alert-success-lighten {
  color: #2c8f41;
  background-color: #edfff1;
  border-color: #a2ebb1;
}

.text-gold-dark {
  color: $gold-dark !important;
}

.text-gold-light {
  color: $gold-light !important;
}

.text-danger-light {
  color: $danger-light;
}

.text-purple {
  color: $purple;
}

.btn-pure {
  border: transparent;
  background: transparent;
}

.text-default {
  color: $body-font-color !important;

  label {
    color: $body-font-color !important;
  }
}

.btn {
  &.btn-xs {
    padding: 0.2rem 0.3rem;
    font-size: 0.73rem;
  }

  &.btn-inactive {
    background-color: #eef2f7;
    color: $body-font-color;
  }

  &.btn-gold-dark {
    background-color: $gold-dark;
    color: $white;
  }

  &.btn-green-dark {
    background-color: $green-dark;
    color: $white;
  }

  &.btn-green {
    background-color: $green;
    color: $white;
    border: $green;
  }

  &.btn-outline-light {
    border-color: #b7babe !important;
    color: #7d828a !important;
  }

  &.btn-outline-green {
    border-color: $green;
    color: $green;

    &:hover {
      background-color: $green;
      color: $white;
    }
  }

  &.btn-navy-dark {
    background: $navy-light;
    color: #fff;

    &:hover {
      color: #eee;
      background: $navy-dark;
    }
  }

  &.btn-hover-muted {
    &:hover {
      background-color: #f2f2f2 !important;
    }
  }
}

.bg-dark-light {
  background-color: #576067;
}

.bg-dark-grey {
  background-color: $dark-gray;
}

.bg-grey {
  background-color: $grey;
}

.bg-grey-dark {
  background-color: #ebebeb;
}

.bg-light-grey {
  background-color: $light-gray;
}

.bg-platinum {
  background-color: $platinum;
}

.bg-white-smoke {
  background-color: $white-smoke;
}

.bg-white-smoke-dark {
  background-color: $white-smoke-dark;

  &.border {
    border-color: #cfd0d0 !important;
  }
}

.bg-inherit {
  background-color: inherit !important;
}

.bg-danger-light {
  background-color: $danger-light;

  &:hover {
    background-color: #c43552;
  }

  &:focus {
    background-color: $danger-light !important;
  }
}

.bg-green {
  background-color: $green;
}

.bg-purple {
  background-color: $purple;

  &:hover {
    background-color: #854fe8;
  }

  &:focus {
    background-color: $purple !important;
  }
}

.booking-checkinfo {
  margin-left: 10px;
  border-left: 1px solid #f1f3fa;
  padding-left: 10px;
  height: 70px;
}

.lock-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 65537;
  transition: all 300ms ease;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;

  &.show {
    visibility: visible !important;
    opacity: 1;
  }

  .spinner {
    width: 81px;
    height: 81px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
  }
}

.hr {
  height: 1px;
  width: 100%;
  overflow: hidden;

  &.hr-strong {
    height: 2px;
  }

  &.hr-grey {
    background-color: $gray-700;
  }

  &.hr-grey-2 {
    background-color: #bbbec0;
  }

  &.hr-white {
    background-color: white;
  }
}

.form-control {
  &:read-only {
    background-color: inherit;
  }

  &:disabled {
    border: 1px solid #dee2e6;
    background-color: #f2f2f2 !important;
  }
}

.form-transparent {
  .form-control {
    background-color: transparent;
  }
}

.date-picker-input.disabled {
  opacity: 1 !important;

  .date-picker,
  .input-group-prepend {
    background-color: #f2f2f2;
  }
}

.no-outline {
  &:focus {
    outline: none;
  }
}

.overflow-x-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
}

.light-scrollbar {
  @include light-scrollbar(4px, 1px);
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1r {
  margin-bottom: 1rem;
}

.m-01 {
  margin: 0.1rem;
}

.mt-label {
  margin-top: 20px;
}

.border-radius-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-radius-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-radius-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-radius-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-gray {
  border-color: #606060 !important;
}

.border-green {
  border-color: $green !important;
}

.modal-semi-md {
  max-width: 350px;
}

.modal-semi-xl {
  max-width: 1000px;
}

.modal-semi-lg {
  max-width: 660px;
}

.modal-auto {
  width: fit-content;
  max-width: fit-content;
}

.modal-xl {
  @include screen-xl-min {
    max-width: 1140px;
  }
}

.tr-border-dark {
  &__top {
    border-top: 2px solid #bfbfbf;
  }

  &__bottom {
    border-bottom: 2px solid #bfbfbf;
  }

  &__middle {
    border-left: 2px solid #bfbfbf;
    border-right: 2px solid #bfbfbf;
  }
}

.input-group {
  &.disabled,
  &:disabled {
    background-color: #f2f2f2;

    .form-control {
      &:disabled {
        opacity: 1;
      }
    }

    .date-range {
      background-color: #f2f2f2;
    }
  }
}

.form-overflowed {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-input-with-icon {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.375rem;
  }

  &__icon {
    width: 17px;
  }

  &__input {
    padding-top: 5px;
    padding-left: 5px;
    width: calc(100% - 17px);
  }
}

.form-datepicker-with-icon {
  border-left: 0;
  padding-left: 0;

  .form-control {
    height: calc(2.1rem);
  }
}

.border-top-0 {
  border-top: 0 !important;
}

.ck-editor__editable {
  min-height: 150px;
}

.valign-middle {
  vertical-align: middle !important;
}

.bg-grey-light {
  background-color: #fbfbfb;
}

input:not(.is-spin-button)::-webkit-outer-spin-button,
input:not(.is-spin-button)::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.font-36 {
  font-size: 36px;
}

.fw-semi-bold {
  font-weight: 500;
}

.btn-flat {
  height: 32px;
  line-height: initial;
}

.btn-tall {
  height: 37px;
  max-height: 37px;
}

div._loading_overlay_spinner svg circle {
  stroke: $gold !important;
}

.badge {
  height: fit-content;

  &.badge-gray {
    background-color: #e6e7e8 !important;
    color: #5a6066 !important;
  }

  &.badge-blue {
    background-color: $status-blue;
    color: white;
  }

  &.badge-green {
    background-color: $status-green;
    color: white;
  }

  &.badge-black {
    background-color: $status-black;
    color: white;
  }

  &.badge-grey {
    background-color: $status-grey;
    color: white;
  }

  &.badge-pink {
    background-color: $status-pink;
  }

  &.badge-green {
    background-color: #2c8f41;
  }

  &.badge-yellow {
    background-color: #f6d73e;
    color: #fff;
  }
}

.not-clickable {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  transition: opacity 100ms linear;
}

.inline-spinner {
  width: 15px;
  height: 15px;
  border-width: 2px;

  &--white {
    color: white !important;
  }
}

.input-group-prepend {
  .is-invalid.input-group-text {
    border-top-color: #dd3535 !important;
    border-bottom-color: #dd3535 !important;
    border-left-color: #dd3535 !important;
  }
}

.pe-none,
.pe-none > * {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.card-grey {
  box-shadow: none;

  .card-body-grey {
    border: 1px solid #cdcecf;
    @include border-radius(3px);
    background: #f5f5f5;
  }
}

.box-shadow-bottom {
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.2);
}

.pos-initial {
  position: initial;
}

.table__header.vertical-align-middle,
.vertical-align-middle {
  vertical-align: middle !important;
}

.custom-radio,
.custom-checkbox {
  cursor: pointer;

  input,
  input + label {
    cursor: pointer;
  }
}

.checkbox-label-disabled-opacity-1 {
  input:disabled + label {
    opacity: 1 !important;
  }
}

.left-0 {
  left: 0;
}

.top {
  top: 0;
}

.lh-initial {
  line-height: initial;
}

.lh-0 {
  line-height: 0;
}

.zi-0 {
  z-index: 0;
}

.border-left-danger {
  border-left: 2px solid $danger;
}

.disabled {
  background-color: #f2f2f2;
  //opacity: 0.4;
  pointer-events: none;
}

.mt-label {
  margin-top: 24px;
}

.custom-control-label {
  display: flex;
  align-items: center;
  min-height: 23px;

  &:after,
  &:before {
    top: 50%;
    transform: translateY(-55%);
  }
}

.h-fit-content {
  height: fit-content;
}

.table-list-indicator {
  &__wrapper {
    &:after {
      content: '';
      position: absolute;
      left: 31px;
      height: calc(100% - 73px);
      width: 1px;
      top: 45px;
      background-color: #adb5bd;
    }
  }

  &__element {
    position: relative;
    margin-left: 10px;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      width: 1px;
      height: 30px;
      top: -23px;
      background-color: #adb5bd;
    }

    &:after {
      content: '';
      position: absolute;
      left: -15px;
      height: 1px;
      width: 7px;
      top: 7px;
      background-color: #adb5bd;
    }
  }
}

.form-checkbox {
  &.no-label {
    .custom-control-label:before,
    .custom-control-label:after {
      top: 0;
      transform: none;
    }
  }
}

.resize-none {
  resize: none;
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: white;
}

.table-fixed {
  table-layout: fixed;
}

.avatar-3xs {
  border-width: 1px;
  height: 13px;
  width: 13px;
}

.tooltip-no-arrow {
  .tooltip .arrow {
    display: none;
  }
}

.pulse-scale {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

;@import "sass-embedded-legacy-load-done:148";